@import url("https://fonts.googleapis.com/css2?family=Barlow:wght@400;600;700;800&display=swap");

body,
html {
  background-color: var(--black);
  font-family: "Barlow", sans-serif;
  overflow-x: hidden !important;
}

:root {
  --font-sm: 16px;
  --font-md: 18px;
  --font-lg: 24px;
  --font-xl: 40px;
  --font-xxl: 64px;
  --pink: rgba(226, 89, 217, 1);
  --blue: rgba(96, 52, 235, 1);
  --white: rgba(255, 255, 255, 1);
  --black: #000000;
}

@media (max-width: 575.98px) {
  :root {
    --font-sm: 13.59px;
    --font-md: 15px;
    --font-lg: 17px;
    --font-xl: 19px;
    --font-xxl: 28px;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  :root {
    --font-sm: 15px;
    --font-md: 16px;
    --font-lg: 24px;
    --font-xl: 38px;
    --font-xxl: 38px;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  :root {
    --font-sm: 16px;
    --font-md: 18px;
    --font-lg: 24px;
    --font-xl: 34px;
    --font-xxl: 50px;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  :root {
    --font-sm: 16px;
    --font-md: 18px;
    --font-lg: 24px;
    --font-xl: 38px;
    --font-xxl: 56px;
  }
}

@media (min-width: 1200px) and (max-width: 1399.98px) {
  :root {
    --font-sm: 16px;
    --font-md: 18px;
    --font-lg: 24px;
    --font-xl: 40px;
    --font-xxl: 62px;
  }
}

@media (min-width: 1400px) {
  :root {
    --font-sm: 16px;
    --font-md: 18px;
    --font-lg: 24px;
    --font-xl: 40px;
    --font-xxl: 64px;
  }
}

.font-sm {
  font-size: var(--font-sm) !important;
}

.font-md {
  font-size: var(--font-md) !important;
}

.font-lg {
  font-size: var(--font-lg) !important;
}

.font-xl {
  font-size: var(--font-xl) !important;
}

.font-xxl {
  font-size: var(--font-xxl) !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-medium {
  font-weight: 500 !important;
}

.fw-semibold {
  font-weight: 600 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-xtra-bold {
  font-weight: 800 !important;
}

.color-pink {
  color: var(--pink) !important;
}

.color-blue {
  color: var(--blue) !important;
}

.text-white {
  color: var(--white) !important;
}

.text-black {
  color: var(--black) !important;
}

.bg-color-pink {
  background-color: var(--pink) !important;
}

.bg-color-blue {
  background-color: var(--blue) !important;
}

.bg-color-white {
  background-color: var(--white) !important;
}

.bg-color-black {
  background-color: var(--black) !important;
}

.main-heading {
  font-size: var(--font-xxl);
  font-weight: 800;
  color: var(--white);
}

.heading {
  font-size: var(--font-xl);
  font-weight: 700;
  color: var(--white);
}

.sub-heading {
  font-size: var(--font-lg);
  font-weight: 600;
  color: var(--white);
}

.small-heading {
  font-size: var(--font-md);
  font-weight: 400;
  color: var(--white);
}

.para {
  font-size: var(--font-sm);
  font-weight: 400;
  color: var(--white);
  line-height: 20px;
}

.common-btn {
  font-size: var(--font-md);
  font-weight: 600;
  color: var(--white);
  background-image: linear-gradient(90deg, #e259d9 4%, #6034eb 100%);
  border-radius: 49px;
  padding: 12px 20px;
  box-shadow: 0px 5px 22px 0px rgba(226, 89, 217, 0.5);
  background-size: 150% auto;
  background-position: left center;
  transition: all 0.4s ease-in-out;
}

.common-btn:hover {
  color: var(--white);
  opacity: 0.9;
  background-position: right center;
}

.my-btn {
  background: transparent;
  border: 1px solid var(--pink) !important;
  box-shadow: none;
  transition: all 0.4s ease-in-out;
}

.my-btn:hover {
  box-shadow: 0px 5px 22px 0px rgba(226, 89, 217, 0.75);
  background: transparent;
  opacity: 0.89 !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

/* HEADER OVERLAY STYLES  */
.fs-30 {
  font-size: 30px;
}

.navbar-wrapper {
  background-color: var(--black);
}

.navbar-wrapper .nav-list-items a {
  font-size: var(--font-sm);
  font-weight: 400;
  color: white;
  text-decoration: none;
  position: relative;
}

.navbar-wrapper .nav-list-items a::after {
  content: "";
  position: absolute;
  bottom: -9px;
  left: 0;
  width: 0;
  height: 3px;
  background: linear-gradient(90deg, #e259d9 4%, #6034eb 100%);
  transition: all 0.3s ease-in-out;
}

.navbar-wrapper .nav-list-items a:hover::after {
  width: 100%;
}

.sale {
  cursor: pointer;
}

.sale::after {
  content: "";
  position: absolute;
  bottom: -9px;
  left: 0;
  width: 0;
  height: 3px;
  background: linear-gradient(90deg, #e259d9 4%, #6034eb 100%);
  transition: all 0.3s ease-in-out;
}

.sale:hover::after {
  width: 100%;
}
/* FOR overlay-nav nav-link-hover */
a.middle {
  position: relative;
}

a.middle::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background: linear-gradient(90deg, #e259d9 4%, #6034eb 100%);
  visibility: hidden;
  transform: scaleX(0);
  transition: all 0.3s ease-in-out;
}

a.middle:hover::after {
  visibility: visible;
  transform: scaleX(1);
}

/* HAMBURGER ICON STYLE  */
@media (max-width: 991.98px) {
  /* NAVBAR OVERLAY TOGGLE */
  .header-logo {
    width: 100px;
  }

  .navbarwrapper {
    position: relative;
    width: 29px;
    cursor: pointer;
    height: 25px;
    z-index: 15;
  }

  .first {
    background-color: #fff;
    width: 100%;
    height: 3px;
    position: absolute;
    border-radius: 20px;
    right: 0;
  }

  .second {
    background-color: #fff;
    width: 70%;
    height: 3px;
    position: absolute;
    right: 0;
    border-radius: 20px;
    margin-top: 10px;
  }

  .third {
    background-color: #fff;
    width: 100%;
    height: 3px;
    position: absolute;
    right: 0;
    border-radius: 20px;
    margin-top: 20px;
  }

  .animate .second {
    opacity: 0;
  }

  .animate .first {
    top: 11px;
    transform: rotate(45deg);
    transition: top 300ms cubic-bezier(0.23, 1, 0.32, 1),
      transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
    z-index: 1;
  }

  .animate .third {
    top: -8px;
    transform: rotate(-45deg);
    transition: top 300ms cubic-bezier(0.23, 1, 0.32, 1),
      transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
    z-index: 1;
  }
}

@media (max-width: 991.98px) {
  .overlay-nav {
    transition: 0.7s ease !important;
    opacity: 0;
    position: fixed;
    top: 0;
    left: 0;
    background-color: black;
    width: 100%;
    height: 100%;
    pointer-events: none;
  }
}

.overlay-active {
  z-index: 10;
  transition: 0.7s ease !important;
  opacity: 1;
  pointer-events: all;
}

.pt-30 {
  padding-top: 30px;
}

.object-fit {
  object-fit: contain;
}

.mb-80 {
  margin-bottom: 80px;
}

.p-10 {
  padding: 10px;
}

.overlay-nav li {
  transform: translateY(10px);
  transition: all 0.4s ease-in-out;
  opacity: 0;
  list-style-type: none;
}

.overlay-active ul li:nth-child(1) {
  animation: animate_links 0.9s;
  animation-delay: 0.2s;
  transform: translateY(10px);
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.overlay-active ul li:nth-child(2) {
  animation: animate_links 0.9s;
  animation-delay: 0.4s;
  transform: translateY(10px);
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.overlay-active ul li:nth-child(3) {
  animation: animate_links 0.9s;
  animation-delay: 0.6s;
  transform: translateY(10px);
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.overlay-active ul li:nth-child(4) {
  animation: animate_links 0.9s;
  animation-delay: 0.8s;
  transform: translateY(10px);
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.overlay-active ul li:nth-child(5) {
  animation: animate_links 0.9s;
  animation-delay: 1s;
  transform: translateY(10px);
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.overlay-active ul li:nth-child(6) {
  animation: animate_links 0.9s;
  animation-delay: 1.2s;
  transform: translateY(10px);
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes animate_links {
  from {
    transform: translateY(20px);
    opacity: 0;
  }

  to {
    transform: translateY(-20px);
    opacity: 1;
  }
}

.h-screen {
  height: 100vh;
}

.fix-screen-height {
  height: 100vh;
  overflow: hidden !important;
}
/* HEADER OVERLAY STYLES  */
.hidden {
  overflow: hidden !important;
  height: 100vh;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.social-icons a svg {
  transition: all 0.4s ease-in-out;
}

.social-icons a svg:hover {
  transform: scale(1.1) translateY(-6px);
  opacity: 0.9;
}

/* PRELOADER STYLE  */
.pre-loader-style {
  position: fixed;
  width: 100%;
  height: 100vh;
  background-color: var(--black);
  overflow: hidden;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.pre-loader-style img {
  max-width: 200px;
}

.load-animation {
  -webkit-animation: load-animation 1.5s ease infinite;
  animation: load-animation 1.5s ease infinite;
}

@-webkit-keyframes load-animation {
  30% {
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
  }

  40%,
  60% {
    -webkit-transform: rotate(-20deg) scale(1.5);
    transform: rotate(-20deg) scale(1.5);
  }

  50% {
    -webkit-transform: rotate(20deg) scale(1.5);
    transform: rotate(20deg) scale(1.5);
  }

  70% {
    -webkit-transform: rotate(0deg) scale(1.5);
    transform: rotate(0deg) scale(1.5);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes load-animation {
  30% {
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
  }

  40%,
  60% {
    -webkit-transform: rotate(-20deg) scale(1.5);
    transform: rotate(-20deg) scale(1.5);
  }

  50% {
    -webkit-transform: rotate(20deg) scale(1.5);
    transform: rotate(20deg) scale(1.5);
  }

  70% {
    -webkit-transform: rotate(0deg) scale(1.5);
    transform: rotate(0deg) scale(1.5);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

/* BackToTop */
.back-to-top {
  position: fixed;
  bottom: 20px;
  right: 35px;
  z-index: 9999;
  font-size: var(--font-sm);
  color: var(--color-white);
  background-image: linear-gradient(90deg, #e259d9 4%, #6034eb 100%);
  border-radius: 50%;
  width: 55px;
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
  animation: updown-element-animation 2s infinite;
  box-shadow: 0px 0px 5px 0px var(--white);
}

@keyframes updown-element-animation {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(10px);
  }

  100% {
    transform: translateY(0);
  }
}

.back-to-top {
  bottom: 35px;
  right: 15px;
  width: 55px;
  height: 55px;
}

.back-to-top:hover {
  box-shadow: 0px 2px 11px 2px var(--white);
}

/* META VERSE BAR  SLIDER  */

.slide img {
  width: 18rem;
  margin: 0 auto;
}

.slide {
  transform: scale(0.5);
  transition: transform 300ms;
  opacity: 0.5;
}

.activeSlide {
  transform: scale(1.1);
  opacity: 1;
}

.bar-slider .slick-list {
  padding: 20px 0 !important;
}
/* ================================== HEADER-STYLE ====================================================== */
.hero-section {
  background: url("../src/assets/img/png/bg-hero-img.png"), #000000;
  background-repeat: no-repeat;
  background-size: cover;
  background-position-x: center;
  background-position-y: bottom;
}

.hero-content {
  min-height: 100vh;
}

.my-text-shadow {
  text-shadow: 0px 5px 30px rgb(96 52 235 / 50%);
}

.guest {
  background: url("../src/assets/img/png/bg-guest-img.png"), #000000;
  background-repeat: no-repeat;
  background-size: cover;
  background-position-x: center;
  min-height: 800px;
}

.email-join-btn {
  background-color: #00000096;
  border: 1px solid rgba(255, 255, 255, 1);
  border-radius: 37px;
}

.my-email::placeholder {
  color: var(--white);
}

.my-email:focus-visible {
  outline: none !important;
}

.metaversebar {
  background: url("../src/assets/img/png/bg-metaversebar-img.png"), #000000;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.the-ip-section {
  background-image: url("../src/assets/img/png/Ip-section-bg-img.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position-x: center;
}

.the-metaverse-bar {
  background: url("../src/assets/img/png/the-metaverse-bar-bg-img-2.png"),
    #000000;
  background-repeat: no-repeat;
  background-size: cover;
  background-position-x: center;
  background-position-y: bottom;
}

.the-metaverse-bar-content {
  min-height: 500px;
}

.team-img img {
  cursor: pointer;
}

.team-slider .team-img img {
  transition: all 0.4s ease-in-out;
}

.team-slider .team-img:hover img {
  transform: scale(1.059) !important;
}

.team-img {
  background: url("../src/assets/img/png/bg-team-img.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position-x: center;
}

/* ============== SLIDER ============= */
.slick-prev {
  background-image: url("../src//assets/img/png/slick-prev-img.png") !important;
  left: -50px !important;
}

.slick-next {
  background-image: url("../src//assets/img/png/slick-next-img.png") !important;
  right: -50px !important;
}

.slick-prev,
.slick-next {
  width: 48px !important;
  height: 48px !important;
  border-radius: 50%;
  transition: all 0.4s ease-in-out;
}

.slick-prev:hover,
.slick-next:hover {
  opacity: 0.9;
  box-shadow: 0px 5px 22px 0px rgba(226, 89, 217, 0.75) !important;
}

.slick-prev:before,
.slick-next:before {
  color: transparent !important;
}

.slick-dots li button:before {
  font-size: 7px !important;
  color: var(--white) !important;
}

.slick-dots li {
  width: 11px !important;
  height: 11px !important;
}

.slick-dots li.slick-active button:before {
  opacity: 1 !important;
}
/* ============== SLIDER ============= */
/* ============== ROADMAP ============ */
.roadmap-circle-phase {
  background: linear-gradient(
    123.9deg,
    #e259d9 6.36%,
    rgba(255, 255, 255, 0.1) 101.73%
  );
  width: 86px;
  height: 86px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.roadmap-circle-phase span {
  width: 82px;
  height: 82px;
  display: inline-block;
  border-radius: 50%;
  z-index: 1;
  background: linear-gradient(
    123.9deg,
    rgb(12 12 12) 0%,
    rgb(24 24 24) 101.73%
  );
}
.roadmap-content {
  padding: 10px 20px;
  background-image: url("../src/assets/img/png/bg-roadmap-content-img.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.line {
  position: relative;
}
.line::after {
  position: absolute;
  content: "";
  background-image: url("./assets/img/png/roadmap-line-img.png");
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  left: 50%;
}
/* ============== ROADMAP ============ */

.limited-logo {
  top: 24%;
  right: 0px;
  width: 22%;
}

/* ====================================================== FLIP-CARDS ============================================================= */
.flip-container {
  perspective: 1000;
}

.flip-container:hover .flipper,
.flip-container.hover .flipper {
  transform: rotateY(180deg);
}

.flip-container,
.front,
.back {
  width: 100%;
  height: 100%;
  min-height: 338px;
}

.flipper {
  transition: 0.6s;
  transform-style: preserve-3d;
  position: relative;
}

.front,
.back {
  backface-visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
  text-align: center;
}

.front {
  z-index: 2;
  transform: rotateY(0deg);
  background-image: url("../src/assets/img/png/character-bg-img.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.back {
  background-image: url("../src/assets/img/png/flip-card-bg-img.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  transform: rotateY(180deg);
}
/* ====================================================== FLIP-CARDS ============================================================= */
.footer-text {
  opacity: 0.5;
  border-top: 1px solid var(--white);
}

.video-element-wrapper {
  height: 322px !important;
  width: 100%;
}

.video-element-wrapper .react-player {
  height: 322px !important;
  width: 100% !important;
}

.border-custom {
  border-radius: 25px;
  background: linear-gradient(
    123.9deg,
    rgba(255, 255, 255, 0.1) 0%,
    rgba(255, 255, 255, 0.03) 101.73%
  );
}
.border-custom .borderr {
  border-image-source: url("./assets/img/png/Rectangle\ 36\ \(2\).png") !important   ;
  border: 11px solid transparent;
  border-image-slice: 10;
  border-image-repeat: round;
  border-radius: 25px;
}

.z-index-1 {
  z-index: 1;
}
/* overlay-lines */
.guest {
  position: relative;
}

.guest::after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 50px;
  background: #000000;
  z-index: 0;
  filter: blur(50px);
  opacity: 0.9;
}

.metaversebar {
  position: relative;
}

.metaversebar::after {
  content: "";
  display: block;
  position: absolute;
  top: -35px;
  right: 0;
  width: 100%;
  height: 90px;
  background: #000000;
  z-index: 0;
  filter: blur(30px);
  opacity: 0.9;
}

.ip-scholarship {
  position: relative;
}
.ip-scholarship::after {
  content: "";
  display: block;
  position: absolute;
  right: 0;
  width: 100%;
  height: 120px;
  background: #000000;
  z-index: 0;
  filter: blur(40px);
  opacity: 0.9;
}

.metaversebar::before {
  content: "";
  display: block;
  position: absolute;
  bottom: -20px;
  right: 0;
  width: 100%;
  height: 60px;
  background: #000000;
  z-index: 0;
  filter: blur(10px);
}

.the-metaverse-bar {
  position: relative;
}

.the-metaverse-bar::after {
  content: "";
  display: block;
  position: absolute;
  top: -50px;
  right: 0;
  width: 100%;
  height: 120px;
  background: #000000;
  z-index: 0;
  filter: blur(22px);
  opacity: 0.9;
}

.the-metaverse-bar::before {
  content: "";
  display: block;
  position: absolute;
  bottom: -10px;
  right: 0;
  width: 100%;
  height: 140px;
  background: #000000;
  z-index: 0;
  filter: blur(40px);
  opacity: 0.9;
}

.meetshooters {
  position: relative;
}

.meetshooters::before {
  content: "";
  display: block;
  position: absolute;
  bottom: 50px;
  right: 0;
  width: 100%;
  height: 130px;
  background: #000000;
  z-index: 0;
  filter: blur(20px);
  opacity: 0.9;
}

.slick-prev,
.slick-next {
  top: 38% !important;
}

.meetshooters-content {
  transform: translate(0px, -30px);
}

.bar-slider {
  transition: all 0.2s ease-in-out;
}

.bar-slider .slick-slide {
  opacity: 0.45;
}

.bar-slider .slick-center {
  opacity: 1;
  transition: all 0.4s ease-in-out;
  transform: scale(1.29) !important;
}

.bar-slider .slick-slide div:focus-visible {
  outline: unset !important;
}

/* THREE D SLIDER STYLE  */
.threeD-slider-card-parent {
  width: 100%;
}
.react-3d-carousel
  .slider-container
  .slider-content
  .slider-single
  .slider-single-content {
  box-shadow: none !important;
}

.swiper {
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
}

.swiper-slide {
  background-position: center;
  background-size: cover;
  width: 50px;
  height: 50px;
}

.swiper-slide img {
  display: block;
  width: 450px;
}
.swiper-3d .swiper-slide-shadow-left {
  background-image: unset !important;
}

.swiper-3d .swiper-slide-shadow-right {
  background-image: unset !important;
}

.logo-img {
  width: 130px !important;
}

#slider {
  position: relative;
  width: 80%;
  height: 28vw;
  margin: 150px auto;
  font-family: "Helvetica Neue", sans-serif;
  perspective: 1400px;
  transform-style: preserve-3d;
}

input[type="radio"] {
  position: relative;
  top: 108%;
  left: 50%;
  width: 18px;
  height: 18px;
  margin: 0 15px 0 0;
  opacity: 0.4;
  transform: translateX(-83px);
  cursor: pointer;
}

input[type="radio"]:nth-child(5) {
  margin-right: 0px;
}

input[type="radio"]:checked {
  opacity: 1;
}

#slider label,
#slider label img {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  color: white;
  font-size: 70px;
  font-weight: bold;
  border-radius: 3px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 400ms ease;
}

#slider label img {
  margin-left: 1rem;
}

/* Slider Functionality */

/* Active Slide */
#s1:checked ~ #slide1,
#s2:checked ~ #slide2,
#s3:checked ~ #slide3,
#s4:checked ~ #slide4,
#s5:checked ~ #slide5 {
  transform: translate3d(0%, 0, 0px);
}

/* Next Slide */
#s1:checked ~ #slide2,
#s2:checked ~ #slide3,
#s3:checked ~ #slide4,
#s4:checked ~ #slide5,
#s5:checked ~ #slide1 {
  transform: translate3d(20%, 0, -100px);
}

/* Next to Next Slide */
#s1:checked ~ #slide3,
#s2:checked ~ #slide4,
#s3:checked ~ #slide5,
#s4:checked ~ #slide1,
#s5:checked ~ #slide2 {
  transform: translate3d(40%, 0, -250px);
}

/* Previous to Previous Slide */
#s1:checked ~ #slide4,
#s2:checked ~ #slide5,
#s3:checked ~ #slide1,
#s4:checked ~ #slide2,
#s5:checked ~ #slide3 {
  transform: translate3d(-40%, 0, -250px);
}

/* Previous Slide */
#s1:checked ~ #slide5,
#s2:checked ~ #slide1,
#s3:checked ~ #slide2,
#s4:checked ~ #slide3,
#s5:checked ~ #slide4 {
  transform: translate3d(-20%, 0, -100px);
}
@media (min-width: 1400px) {
  .my-container {
    max-width: 1390px;
  }

  .lady-img {
    max-width: 116px;
  }
}

@media (min-width: 1600px) {
  .bar-slider .slick-list {
    padding: 25px 0 !important;
  }
}

@media (max-width: 1499.98px) {
  .limited-logo {
    top: 24%;
    width: 26%;
  }

  .metaversebar::after {
    top: 0;
    height: 40px;
  }

  .meetshooters::before {
    bottom: 65px;
  }

  .meetshooters-content {
    transform: translate(0px, -20px);
  }
}

@media (max-width: 1399.98px) {
  .slick-prev {
    left: -40px !important;
  }

  .slick-next {
    right: -40px !important;
  }

  .the-metaverse-bar-content {
    min-height: 400px;
  }

  .meetshooters::before {
    bottom: 40px;
  }
  .slide img {
    width: 14em;
    margin: 0 auto;
  }

  .flip-container,
  .front,
  .back {
    width: 100%;
    height: 100%;
    min-height: 358px;
  }
}

@media (max-width: 1199.98px) {
  .slick-prev {
    left: -15px !important;
  }

  .slick-next {
    right: -15px !important;
  }

  .limited-logo {
    top: 34%;
    width: 24%;
  }

  .meetshooters-content {
    transform: translate(0px, 0px);
  }

  .flip-container,
  .front,
  .back {
    min-height: 322px;
  }
}

@media (max-width: 991.98px) {
  .slick-prev {
    left: -25px !important;
  }

  .slick-next {
    right: -25px !important;
  }

  .roadmap-circle-phase {
    width: 66px;
    height: 66px;
  }
  .roadmap-circle-phase span {
    width: 62px;
    height: 62px;
  }

  .limited-logo {
    display: none;
  }

  .metaversebar::after {
    height: 0;
  }

  .meetshooters::before {
    bottom: 0px;
  }
}

@media (max-width: 575.98px) {
  .slide img {
    width: 7em;
  }
  .activeSlide {
    transform: scale(1.2);
    opacity: 1;
  }

  .common-btn {
    padding: 6px 14px;
  }

  .roadmap-circle-phase {
    width: 36px;
    height: 36px;
    font-size: 20px !important;
  }
  .roadmap-circle-phase span {
    width: 32px;
    height: 32px;
  }

  .roadmap-content {
    padding: 10px 10px;
  }

  .the-metaverse-bar-content {
    min-height: unset;
  }

  .guest {
    min-height: unset;
  }

  .video-element-wrapper {
    height: 166px !important;
  }

  .video-element-wrapper .react-player {
    height: 166px !important;
  }

  .hero-section {
    position: relative;
  }

  .hero-section::after {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: #0000007a;
    z-index: 0;
  }

  .back-to-top {
    width: 45px;
    height: 45px;
  }

  .overlay-nav .nav-btn {
    font-size: 22px !important;
  }

  .ip-scholarship::after {
    height: 0;
  }

  .meetshooters::before {
    bottom: -20px;
  }

  .hero-content {
    min-height: 75vh;
  }

  .lady-img {
    max-width: 123px;
  }

  .flip-container,
  .front,
  .back {
    min-height: 358px;
  }

  .logo-img {
    width: 93px !important;
  }

  .pre-loader-style img {
    max-width: 150px;
  }
}
