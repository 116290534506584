body {
  background: black;
}

.sale-bg {
  background-position: center;
  min-height: 80vh;
  background-size: cover;
  background-image: url("../src/assets/img/png/salebg.png");
}
.founder-heading {
  max-width: 362px;
}
.get-btn {
  box-shadow: 0px 5px 22px 0px rgba(226, 89, 217, 0.5);
}
/* roadmap-css */
.roadmap {
  background-repeat: no-repeat;
  min-height: 154vh;
  background-size: cover;
  background-image: url("../src/assets/img/png/roadmapbg.png");
  margin-top: -100px;
  /* margin-bottom: -145px; */
}
.mt-25 {
  margin-top: -25px !important;
}
/* roadmap-css-ends */

/* marya-css */
.Marya {
  background-size: cover;
  margin-top: -44px;
  background-repeat: no-repeat;
  min-height: 128vh;
  background-image: url("../src/assets/img/png/metashoterspart.png");
  background-position-y: center;
}
.marya-heading {
  font-size: 36px;
}
.maray-text {
  top: 22px;
}
/* marya-css-ends */

.Marya {
  position: relative;
}

.Marya::before {
  content: "";
  display: block;
  position: absolute;
  top: -20px;
  right: 0;
  width: 100%;
  height: 90px;
  background: #000000;
  z-index: 0;
  filter: blur(30px);
  opacity: 0.9;
}

@media (max-width: 1366px) {
  .roadmap {
    min-height: 160vh;
    background-position: top center;
    background-size: inherit !important;
    margin-bottom: 0 !important;
  }
  .Marya {
    margin-top: 1px !important;
  }
}
@media (max-width: 1199.98px) {
  .roadmap {
    min-height: 110vh;
    background-position: top center;
    background-size: inherit !important;
  }
  .pe-30 {
    padding-right: 30px;
  }
  .ms-35 {
    margin-left: 35px !important;
  }
  .roadmap-line-second {
    height: 174px;
    width: 28px;
  }
}
@media (max-width: 992.98px) {
  .roadmap {
    min-height: 90vh;
    margin-bottom: 0px !important;
    background-size: cover;
    margin-top: -93px;
  }
  .roadmap-line {
    margin-left: 39px !important;
  }
  .roadmap-line-second {
    height: 114px;
    width: 5px;
    margin-left: 80px !important;
  }
}
@media (max-width: 768.98px) {
  .roadmap-line-second {
    margin-left: 70px !important;
  }
  .roadmap {
    min-height: 126vh;
    background-position: top center;
    background-size: inherit !important;
  }
}
@media (max-width: 575.98px) {
  :root {
    --font-lg: 21px !important;
  }
  .roadmap-line {
    margin-left: 17px !important;
  }
  .roadmap-line-second {
    margin-left: 30px !important;
  }
  .roadmap {
    min-height: 135vh;
  }
  .border-custom .borderr {
    border-image-slice: 17;
    border-radius: 25px;
  }
  .Marya::before {
    top: -30px;
    right: 0;
    width: 100%;
    height: 90px;
    background: #000000;
    z-index: 0;
    filter: blur(10px);
    opacity: 0.9;
  }
}
@media (max-width: 475.98px) {
  .height-100 {
    height: 100% !important;
  }
  .w-21 {
    width: 21px;
  }
  .roadmap-line {
    height: 100px;
    margin-left: 10px !important;
  }
  .roadmap-line-second {
    margin-left: 24px !important;
  }
  .mt-25 {
    margin-top: 0px !important;
  }
  .border-custom .borderr {
    border-image-slice: 14 !important;
  }
}
@media (max-width: 399.98px) {
  :root {
    --font-lg: 15px !important;
  }
  .roadmap-line {
    height: 132px !important;
    width: 19px;
    margin-left: 0px !important;
  }
  .roadmap-line-second {
    height: 154px;
    width: 3px;
    margin-left: 16px !important;
  }
  .height-100 {
    height: 100% !important;
  }
  .Marya {
    margin-top: -44px;
    min-height: 110vh;
  }
  .border-custom .borderr {
    border-image-slice: 11 !important;
  }
  .roadmap {
    min-height: 134vh;
    margin-bottom: -23px;
  }
}
